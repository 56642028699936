import React, { useState } from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints"; 

import Layout from "../components/wrappers/layout"
import SEO from "../components/seo"
import SiteHeaderAlt from "../components/wrappers/site-header-alt"
import BlogList from "../components/blog-list"
import Header from '../components/header';
import { graphql } from 'gatsby';
import { getSinglePage, scrollToSection } from "../utils";
import { useEffect } from "react"
import { useContext } from "react"
import { GlobalDispatchContext, GlobalStateContext } from "../context/GlobalContextProvider"

import box_view from "../images/box-view.svg"; 
import list_view from "../images/list-view.svg"; 
import classNames from "classnames"

const Blogs = (props) => {
  const { footers, general, headers, blogs, blogsPage, blogsOverview } = props.data.strapi;
  const { generalBackgroundColorDarkMode, generalBackgroundColorLightMode  , fontColorDarkMode, fontColorLightMode, hashtagsBackgroundColor, hashtagsBackgroundColorDarkMode, pageTransitionBgColorLightMode, pageTransitionBgColorDarkMode } = general; 
  const { copyright } = blogsOverview;
  const filteredCasesBlogs = blogs.filter((blog) => !blog.inactive); 
  const language = props.pageContext.language
  const header = headers && headers.find((header)=>  header.language === language ); 
  const footer = footers && footers.find((footer)=>  footer.language === language ) || {}; 
  const heading = blogsOverview && blogsOverview.heading; 
  const dispatch = useContext(GlobalDispatchContext); 
  const state = useContext(GlobalStateContext);
  const isSubOpen = state.siteSubNav;
  const [activeDisplay, setActiveDisplay] = useState("");
  const breakpoints = useBreakpoint(); 
  const seoTitle = blogsPage && blogsPage.seo && blogsPage.seo.metaData.metaTitle || "Blog"+" "+ `${heading}` || "blog list ";
  const seoDescription = blogsPage &&  blogsPage.seo && blogsPage.seo.metaData.metaDescription || "Blog" + " " + `${heading}`||"blog list";
  const seoKeywords = blogsPage &&  blogsPage.seo && blogsPage.seo?.metaData?.keywords; 

  const webpages = blogsOverview && blogsOverview.webPages && blogsOverview.webPages || {}; 
  const allPages = props.data?.allSitePage?.nodes; 
  const redirectTo = getSinglePage(webpages, allPages); 
  const darkMode = state.darkModePreference === 'moon'; 
  const blogsOverviewHeadline = general && general?.blogsOverviewHeadline;
  const blogOverviewHeading = blogsOverviewHeadline ? blogsOverviewHeadline : (heading ? heading : '');
  const {imageBorderRadiusForServices } = general; 
  const sectionBackgroundColor = darkMode ? generalBackgroundColorDarkMode : generalBackgroundColorLightMode; 
  const websiteFontColor = darkMode ? fontColorDarkMode : fontColorLightMode; 
  const displayedHashtagBgColor = darkMode ? hashtagsBackgroundColorDarkMode : hashtagsBackgroundColor;
  const pageTransitionBgColor = darkMode ? pageTransitionBgColorDarkMode : pageTransitionBgColorLightMode; 
  if (copyright) {
    footer.copyright = copyright;
  }

  const handleClick = (state) => {
    const isBrowser = typeof window !== "undefined";
    document.body.scrollTo(0,0)
    if (isBrowser) {
      localStorage.setItem("preferredView", state)
    }
    setActiveDisplay(state)
  }

  useEffect(() => {

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('parallax-effect');
          observer.unobserve(entry.target); 
        } else {

        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      rootMargin: '0px',
      threshold: breakpoints.sm ? 0 : 0.1, // Adjust this value based on when you want the effect to trigger
    });

    // Add each parallax item to the observer
    const items = document.querySelectorAll('.parallax-item');
    items.forEach((item) => {
      observer.observe(item);
    });

    // Clean up the observer on component unmount
    return () => {
      items.forEach((item) => {
        observer.unobserve(item);
      });
    };
  }, [activeDisplay]); 


  useEffect(() => {
    const id = "blog"; 
    const isBrowser = typeof window !== "undefined";
    
    if (isSubOpen[id]) {
      dispatch({ type: "RESET_SITE_SUB_NAV"});
    }   

    if (isBrowser) {
      const preferredView = window.localStorage.getItem("preferredView"); 
      if(preferredView) {
        setActiveDisplay(preferredView)
      } else {
        setActiveDisplay("")
      }
    }

    const scrollTo = state.scrollToSection; 
    if (scrollTo) {
    scrollToSection(scrollTo) 
  }
  }, []); 

  return (
    <Layout header={header} footer={footer} language={language} redirectTo={redirectTo}>
      <SEO
        websiteFontColor={websiteFontColor} 
        template="blogs" 
        title={seoTitle} 
        description={seoDescription} 
        keywords={seoKeywords} 
        darkTheme={blogsOverview.darkTheme}
        darkMode={darkMode} 
        websiteBackgroundColor={sectionBackgroundColor}
      />
      <SiteHeaderAlt header={header} redirectTo={redirectTo}>
        <section className="section section-small-margin" style={{backgroundColor: sectionBackgroundColor}} >
          <Header 
            heading={blogOverviewHeading} 
            withFullWidth={true} 
          />          
          <div className="container">
            <div className="row">
              <div className={classNames({"col-lg-10": activeDisplay , "col-lg-12": !activeDisplay})}>
                <BlogList  
                  blogEntries={filteredCasesBlogs} 
                  isCompact={false}
                  isBlogListOverview={true} 
                  imageBorderRadiusForServices={imageBorderRadiusForServices}
                  activeDisplay={activeDisplay}
                  showParallax={true}
                  hashtagsBackgroundColor={displayedHashtagBgColor}
                  showFullWidthOnMobile={!activeDisplay && breakpoints.sm}
                  pageTransitionBgColor={pageTransitionBgColor}
                />
                <div className="switch-btn switch-btn-layout">
                  <div 
                    className={classNames("box", {"active": activeDisplay === ""})}
                    onClick={() => handleClick("")}
                  >
                    <img src={box_view} />    
                  </div>
                  <div 
                    className={classNames("menu ", {"active": activeDisplay === "list"})}
                    onClick={() => handleClick("list")}
                  >
                    <img src={list_view} />    
                </div>
                </div>
              </div>
            </div>

          </div>
        </section>
      </SiteHeaderAlt>
    </Layout>
  )
}

export const pageQuery = graphql`
  query Blogs($language: String!, $id: ID!) {
    allSitePage {
      nodes {
        path
      }
    }
    strapi {
      blogsOverview(id: $id) { 
       copyright
       darkTheme
       heading
       webPages {
          about_us {
            language
          }
          contact {
            language
          }
          case_study {
            slug
            language
          }
          careers_overview {
            language
          }	
          services_overview {
            language
          }
          blog {
            language
            slug
          }
          blogs_overview {
            language
          }
          cases_overview {
            language
          }
          career {
            language
            slug
          }
          homepage {
            slug
            language
          }
          service_design {
            slug
            language
          }
          service_technical {
            slug
            language
          }
          service_casey_new {
            slug
            language
          }
          service_casey {
            slug
            language
          }
          plainpage {
            language
            slug
          }
        }
      }
      general {
        primaryBtn
        blogsOverviewHeadline
        imageBorderRadiusForServices
        generalBackgroundColorDarkMode
        generalBackgroundColorLightMode
        generalBackgroundColorDarkMode
        generalBackgroundColorLightMode
        fontColorDarkMode
        fontColorLightMode
        hashtagsBackgroundColor
        hashtagsBackgroundColorDarkMode
        pageTransitionBgColorLightMode
        pageTransitionBgColorDarkMode
      }
      headers(where: {language: $language}) {
        ...SiteHeader
      }
      footers(where: { language: $language }) {
        ...SiteFooter
      }

      blogs(where: {language: $language}, sort: "date:desc") {
        ...BlogListItem
      }
      blogsPage{
        seo {
          metaData {
            metaDescription
            metaTitle
          }
        }
      }
    }
  }
`

export default Blogs
